import { makeStyles } from '@mui/styles';
import { Colors } from '../../../utils/color';

export default makeStyles((theme) => ({
  dialog_mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.EXTRA_GRAY,
    zIndex: 2,
  },
  dialog_container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: 440,
    zIndex: 3,
    padding: 25,
    backgroundColor: theme.palette.dialog_background,
    overflow: 'auto',
  },
  form_details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
  },
  form_label: {
    color: theme.palette.form_label,
  },
  form_input: {
    '&::placeholder': {
      color: theme.palette.input_placeholder,
    },
    backgroundColor: 'transparent',
    color: theme.palette.profile_title,
    // border: `1px solid ${theme.palette.input_border}`,
    // '&:focus': {
    //   border: `1px solid ${theme.palette.input_border}`,
    // },
  },
  form_input_container: {
    marginTop: 12,
  },
  add_button_container: {
    marginTop: 17,
  },
  add_button: {
    backgroundColor: theme.palette.background_button,
    color: theme.palette.background_button_text,
    '&:hover': {
      backgroundColor: theme.palette.background_button,
    },
  },
  date_selector_container: {
    width: '100%',
  },
  msgLabel: {
    color: theme.palette.title_text,
    fontWeight: 400,
    fontSize: '14px !important',
    fontFamily: "'Open Sans', sans-serif !important",
  },
  add_new_button: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background_button}`,
    color: theme.palette.background_button,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '13px 24px',
    marginTop: 15,
  },
  selectFiles: {
    display: 'none',
  },
}));
