import axios from 'axios';
import { EXPORT_VENDORS } from '../../constants';
import { DownloadCSVorPDF } from '../../../utils/appUtils';

export const exportVendors = (vendorInfo, fileName, onError) => (dispatch) => {
  const token = localStorage.jwtToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    method: 'post',
    url: '/vendor/export',
    data: vendorInfo,
    responseType: 'blob',
  };

  dispatch(loadExportVendors());
  axios(config)
    .then((res) => {
      dispatch(setExportVendors(res));
      DownloadCSVorPDF(res.data, `${fileName}.xlsx`);
    })
    .catch((err) => {
      dispatch(errorExportVendors(err.response));
      onError(err.response);
    });
};

export const loadExportVendors = () => ({
  type: EXPORT_VENDORS.LOAD,
});

export const setExportVendors = (data) => ({
  type: EXPORT_VENDORS.SUCCESS,
  payload: data.data,
});

export const errorExportVendors = (error) => ({
  type: EXPORT_VENDORS.FAIL,
  payload: error,
});
