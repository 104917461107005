import React, { useEffect, useState, useRef } from 'react';
import NewFormHeader from '../../../components/NewFormHeader';
import { Strings } from '../../../utils/strings';
import useStyles from './styles';
import { useFormik, Form, FormikProvider } from 'formik';
import Textbox from '../../../components/Textbox';
import Dropdown from '../../../components/Dropdown';
import {
  EditorFormats,
  EditorModules,
  announcementVisibleArray,
  getErrorMessage,
  manageNotesHref,
} from '../../../utils/appUtils';
import Button from '../../../components/Button';
import { useTheme } from '@mui/material/styles';
import DateSelector from '../../../components/DateSelector';
import TimeSelector from '../../../components/TimeSelector';
import DateTimeContainer from '../../../components/DateTimeContainer';
import { AddAnnouncementSchema } from '../../../utils/validationSchemas';
import moment from 'moment';
import { connect } from 'react-redux';
import { createAnnouncement } from '../../../redux/actions/adminActions/addAnnouncement';
import { uploadFile } from '../../../redux/actions/adminActions/uploadFile';
import { deleteFile } from '../../../redux/actions/adminActions/deleteFile';
import { useAlert } from 'react-alert';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Stack, Typography } from '@mui/material';
import TagsInputField from '../../../components/TagsInput';
import Attachment from '../../../components/Attachment';
import MediaDetails from '../../Media/MediaDetails';
import { getClientsList } from '../../../redux/actions/commonActions/getClientsList';
import { icons } from '../../../utils/icons';

const NewAnnouncement = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const [annoumncementVisibleList, setAnnouncementVisibleList] = useState(
    announcementVisibleArray()
  );
  const [quillData, setQuillData] = useState({ theme: 'snow' });
  const [attachments, setAttachments] = useState([]);
  const inputRef = useRef(null);
  const [openImageInfoDialog, setOpenImageInfoDialog] = useState(false);
  const [fetchedFileInfo, setFetchedFileInfo] = useState({});
  const [deletedFilesList, setDeletedFilesList] = useState([]);
  const [showClientSelection, setShowClientSelection] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [originalClientsList, setOriginalClientsList] = useState([]);

  const renderTags = (props) => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag)}
        <a className={classNameRemove} onClick={(e) => onRemove(key)} />
      </span>
    );
  };

  const onError = (err) => {
    const error = getErrorMessage(err);
    if (error) {
      alert.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      visibleTo: [],
      startDate: '',
      endDate: '',
      startTime: moment(),
      endTime: moment(),
      ccEmails: [],
      attachments: [],
    },
    validationSchema: AddAnnouncementSchema,

    onSubmit: (values) => {
      addAnnouncement(values);
    },
  });

  const addAnnouncement = (values) => {
    let startDateTime = moment(
      moment(values.startDate).format('YYYY-MM-DD') +
        ' ' +
        moment(values.startTime).format('HH:mm')
    ).format();
    let endDateTime = moment(
      moment(values.endDate).format('YYYY-MM-DD') +
        ' ' +
        moment(values.endTime).format('HH:mm')
    ).format();
    const data = {
      title: values.title,
      message: values.message?.replace('<p><br></p>', ''),
      visibleTo: values.visibleTo.map((item) => item.value),
      startDate: startDateTime,
      endDate: endDateTime,
      attachments: values.attachments,
      excludedUsers: getDeselectedClients().map((client) => client.value),
    };

    if (values?.ccEmails?.length) data.ccEmails = values.ccEmails;

    props.createAnnouncement(data, (err) => onError(err), props.onNewClose);
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } =
    formik;

  useEffect(() => {
    if (values.message) manageNotesHref('announcementMessage');
  }, [values.message]);

  const getDeselectedClients = () => {
    return originalClientsList.filter(
      (client) =>
        !selectedClients.some((selected) => selected.value === client.value)
    );
  };

  const onUploadAttachmentsFile = (files) => {
    const newData = [...attachments, ...files];
    setAttachments(newData);
    setFieldValue('attachments', newData);
  };

  const handleFileUpload = async (event) => {
    let newFiles = event.target.files || {};
    if (Object(newFiles)?.length > 0) {
      const data = { files: newFiles };
      props.uploadFile(data, (res) => onUploadAttachmentsFile(res), onError);
    }
  };

  const onClickRemove = async (file, index) => {
    let data = { url: file?.url };
    if (!props.isEdit) props.deleteFile(data, onError);
    let newAttachments = attachments.filter((i, ind) => index !== ind);
    let attachs = [...values.attachments];
    let newAttachments1 = attachs.filter((i, ind) => index !== ind);
    await setAttachments(newAttachments);
    await setFieldValue('attachments', newAttachments1);
    if (props?.isEdit) {
      let deleteData = {
        _id: file?._id,
        url: file?.url,
      };
      setDeletedFilesList([...deletedFilesList, deleteData]);
      setFieldValue('deletedFiles', [...deletedFilesList, deleteData]);
    }
  };

  const onClickPreview = (item, index) => {
    setOpenImageInfoDialog(true);
    setFetchedFileInfo(item);
  };

  const handleVisibleToChange = (value) => {
    const hasAllClients = value.some(
      (item) => item.value === 'client' && item.title === 'All Clients'
    );

    setShowClientSelection(hasAllClients);
    setFieldValue('visibleTo', value);

    if (hasAllClients) {
      props
        .getClientsList({ userTypes: ['client'] }, (err) => onError(err))
        .then((data) => {
          if (data && Array.isArray(data)) {
            const formattedClients = data.map((client) => ({
              value: client._id,
              title: client.name,
            }));
            setClientsList(formattedClients);
            setOriginalClientsList(formattedClients);
            setSelectedClients(formattedClients);
          } else {
            console.error('Received data is not in the expected format:', data);
            setClientsList([]);
          }
        });
    } else {
      setClientsList([]);
      setOriginalClientsList([]);
      setSelectedClients([]);
    }
  };

  const handleClientSelectionChange = (selectedOptions) => {
    setSelectedClients(selectedOptions);
  };

  return (
    <>
      <div className={classes.dialog_mask} />
      <div className={classes.dialog_container}>
        <NewFormHeader
          title={Strings.NEW_ANNOUNCEMENT}
          onNewClose={props.onNewClose}
        />
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <div className={classes.form_details}>
              <Textbox
                name='title'
                onChange={handleChange('title')}
                value={values.title}
                label={Strings.TITLE}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                placeholder={Strings.ENTER_TITLE}
                labelClassName={classes.form_label}
                className={classes.form_input}
              />

              <div style={{ marginTop: 12 }}>
                <label
                  className={classes.form_label}
                  style={{ marginBottom: 7, display: 'inline-block' }}
                >{`Add cc's`}</label>
                <TagsInputField
                  selectedTags={(value) => setFieldValue('ccEmails', value)}
                  placeholder={'Add Emails'}
                  tags={values?.ccEmails || []}
                  renderTag={renderTags}
                />
              </div>

              <Stack spacing={0.9} mt={1}>
                <Typography className={classes.msgLabel}> Message </Typography>
                <ReactQuill
                  id='announcementMessage'
                  theme={quillData.theme}
                  onChange={(value) => setFieldValue('message', value)}
                  value={values.message}
                  placeholder={'Type here'}
                  modules={EditorModules}
                  formats={EditorFormats}
                  error={Boolean(touched.message && errors.message)}
                  helperText={touched.message && errors.message}
                />
              </Stack>
              {/* <Textbox
                name='message'
                onChange={handleChange('message')}
                value={values.message}
                label={Strings.MESSAGE}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
                placeholder={Strings.TYPE_HERE}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
                multiline
                rows={5}
              /> */}
              <MultiSelectDropdown
                name='visibleTo'
                onChange={handleVisibleToChange}
                value={values.visibleTo}
                label={Strings.VISIBLE_TO}
                error={Boolean(touched.visibleTo && errors.visibleTo)}
                helperText={touched.visibleTo && errors.visibleTo}
                title={Strings.SELECT_AN_OPTION}
                labelClassName={classes.form_label}
                className={classes.form_input}
                containerClassName={classes.form_input_container}
                optionArray={annoumncementVisibleList}
                hideAvatar={true}
              />

              {showClientSelection && (
                <MultiSelectDropdown
                  name='selectedClients'
                  onChange={handleClientSelectionChange}
                  value={selectedClients}
                  label='Select Specific Clients'
                  title='Select Clients'
                  labelClassName={classes.form_label}
                  className={classes.form_input}
                  containerClassName={classes.form_input_container}
                  optionArray={clientsList}
                  hideAvatar={true}
                />
              )}

              <DateTimeContainer
                label={Strings.START_DATE_TIME}
                containerClassName={classes.form_input_container}
              >
                <DateSelector
                  name='startDate'
                  onChange={(value) => setFieldValue('startDate', value)}
                  value={values.startDate}
                  error={Boolean(touched.startDate && errors.startDate)}
                  helperText={touched.startDate && errors.startDate}
                  format='MMM dd, yyyy'
                  placeholder={Strings.SAMPLE_DATE}
                  containerClassName={classes.date_selector_container}
                  minDate={new Date()}
                />
                <TimeSelector
                  name='startTime'
                  onChange={(value) =>
                    setFieldValue(
                      'startTime',
                      value ? moment(value, 'HH:mm') : ''
                    )
                  }
                  value={values.startTime}
                  error={Boolean(touched.startTime && errors.startTime)}
                  helperText={touched.startTime && errors.startTime}
                  placeholder={Strings.SAMPLE_TIME}
                />
              </DateTimeContainer>
              <DateTimeContainer
                label={Strings.END_DATE_TIME}
                containerClassName={classes.form_input_container}
              >
                <DateSelector
                  name='endDate'
                  onChange={(value) => setFieldValue('endDate', value)}
                  value={values.endDate}
                  error={Boolean(touched.endDate && errors.endDate)}
                  helperText={touched.endDate && errors.endDate}
                  format='MMM dd, yyyy'
                  placeholder={Strings.SAMPLE_DATE}
                  containerClassName={classes.date_selector_container}
                  minDate={new Date()}
                />
                <TimeSelector
                  name='endTime'
                  onChange={(value) =>
                    setFieldValue(
                      'endTime',
                      value ? moment(value, 'HH:mm') : ''
                    )
                  }
                  value={values.endTime}
                  error={Boolean(touched.endTime && errors.endTime)}
                  helperText={touched.endTime && errors.endTime}
                  placeholder={Strings.SAMPLE_TIME}
                />
              </DateTimeContainer>
              <input
                type='file'
                ref={inputRef}
                className={classes.selectFiles}
                onChange={(event) => handleFileUpload(event)}
                multiple
                accept='*'
              />
              <Button
                text={Strings.ADD_ATTACHMENTS}
                className={classes.add_new_button}
                leftIcon={icons(theme).attachmentIcon}
                onClick={() => inputRef.current.click()}
                loading={props.uploadFileLoading}
                disabled={props.uploadFileLoading}
                loaderColor={theme.palette.background_button}
              />
              <Attachment
                attachments={attachments}
                onClickRemove={onClickRemove}
                onClickPreview={onClickPreview}
              />
              <Button
                type='submit'
                text={Strings.ADD_NEW_ANNOUNCEMENT}
                loading={props.annAnnouncementLoading}
                disabled={props.annAnnouncementLoading}
                containerClassName={classes.add_button_container}
                className={classes.add_button}
                loaderColor={theme.palette.background_button_text}
              />
            </div>
          </Form>
        </FormikProvider>
      </div>
      {openImageInfoDialog && (
        <MediaDetails
          fileInfo={fetchedFileInfo}
          onNewClose={() => {
            setOpenImageInfoDialog(false);
            setFetchedFileInfo();
          }}
          isOnlyMediaPreview={true}
          isAddTagsRequired={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  addAnnouncementData: state.addAnnouncementData.data,
  annAnnouncementLoading: state.addAnnouncementData.loading,

  uploadFileLoading: state.uploadFileData.loading,
});

export default connect(mapStateToProps, {
  createAnnouncement,
  uploadFile,
  deleteFile,
  getClientsList,
})(NewAnnouncement);
