const LOGIN = {
  LOAD: 'LOAD_LOGIN',
  SUCCESS: 'SUCCESS_LOGIN',
  FAIL: 'FAIL_LOGIN',
};

const ADD_USER = {
  LOAD: 'LOAD_ADD_USER',
  SUCCESS: 'SUCCESS_ADD_USER',
  FAIL: 'FAIL_ADD_USER',
};

const UPDATE_USER = {
  LOAD: 'LOAD_UPDATE_USER',
  SUCCESS: 'SUCCESS_UPDATE_USER',
  FAIL: 'FAIL_UPDATE_USER',
};

const ADD_VENDOR = {
  LOAD: 'LOAD_ADD_VENDOR',
  SUCCESS: 'SUCCESS_ADD_VENDOR',
  FAIL: 'FAIL_ADD_VENDOR',
};

const DELETE_VENDOR = {
  LOAD: 'LOAD_DELETE_VENDOR',
  SUCCESS: 'SUCCESS_DELETE_VENDOR',
  FAIL: 'FAIL_DELETE_VENDOR',
};

const UPDATE_VENDOR = {
  LOAD: 'LOAD_UPDATE_VENDOR',
  SUCCESS: 'SUCCESS_UPDATE_VENDOR',
  FAIL: 'FAIL_UPDATE_VENDOR',
};

const ADD_PROGRAM = {
  LOAD: 'LOAD_ADD_PROGRAM',
  SUCCESS: 'SUCCESS_ADD_PROGRAM',
  FAIL: 'FAIL_ADD_PROGRAM',
};

const TEAM_USER_LIST = {
  LOAD: 'LOAD_TEAM_USER_DATA',
  SUCCESS: 'SUCCESS_TEAM_USER_DATA',
  FAIL: 'FAIL_TEAM_USER_DATA',
};

const VENDOR_LIST = {
  LOAD: 'LOAD_VENDOR_LIST_DATA',
  SUCCESS: 'SUCCESS_VENDOR_LIST_DATA',
  FAIL: 'FAIL_VENDOR_LIST_DATA',
};

export const EXPORT_VENDORS = {
  LOAD: 'EXPORT_VENDORS_LOAD',
  SUCCESS: 'EXPORT_VENDORS_SUCCESS',
  FAIL: 'EXPORT_VENDORS_FAIL',
};

const PROGRAM_LIST = {
  LOAD: 'LOAD_PROGRAM_LIST_DATA',
  SUCCESS: 'SUCCESS_PROGRAM_LIST_DATA',
  FAIL: 'FAIL_PROGRAM_LIST_DATA',
};

const USER_LIST_BY_TYPE = {
  LOAD: 'LOAD_USER_LIST_BY_TYPE',
  SUCCESS: 'SUCCESS_USER_LIST_BY_TYPE',
  FAIL: 'FAIL_USER_LIST_BY_TYPE',
};

const ADD_CLIENT = {
  LOAD: 'LOAD_ADD_CLIENT',
  SUCCESS: 'SUCCESS_ADD_CLIENT',
  FAIL: 'FAIL_ADD_CLIENT',
};

const ARCHIVE_UNARCHIVE_CLIENT = {
  LOAD: 'LOAD_ARCHIVE_UNARCHIVE_CLIENT',
  SUCCESS: 'SUCCESS_ARCHIVE_UNARCHIVE_CLIENT',
  FAIL: 'FAIL_ARCHIVE_UNARCHIVE_CLIENT',
};

const CLIENT_LIST = {
  LOAD: 'LOAD_CLIENT_LIST_DATA',
  SUCCESS: 'SUCCESS_CLIENT_LIST_DATA',
  FAIL: 'FAIL_CLIENT_LIST_DATA',
};

const DELETE_CLIENT = {
  LOAD: 'LOAD_DELETE_CLIENT',
  SUCCESS: 'SUCCESS_DELETE_CLIENT',
  FAIL: 'FAIL_DELETE_CLIENT',
};

const UPDATE_CLIENT = {
  LOAD: 'LOAD_UPDATE_CLIENT',
  SUCCESS: 'SUCCESS_UPDATE_CLIENT',
  FAIL: 'FAIL_UPDATE_CLIENT',
};

const ADD_PROPERTY = {
  LOAD: 'LOAD_ADD_PROPERTY',
  SUCCESS: 'SUCCESS_ADD_PROPERTY',
  FAIL: 'FAIL_ADD_PROPERTY',
};

const GET_PROPERTY_BY_ID = {
  LOAD: 'LOAD_GET_PROPERTY_BY_ID',
  SUCCESS: 'SUCCESS_GET_PROPERTY_BY_ID',
  FAIL: 'FAIL_GET_PROPERTY_BY_ID',
};

const PROPERTIES_LIST = {
  LOAD: 'LOAD_PROPERTIES_LIST',
  SUCCESS: 'SUCCESS_PROPERTIES_LIST',
  FAIL: 'FAIL_PROPERTIES_LIST',
};

const DELETE_PROPERTY = {
  LOAD: 'LOAD_DELETE_PROPERTY',
  SUCCESS: 'SUCCESS_DELETE_PROPERTY',
  FAIL: 'FAIL_DELETE_PROPERTY',
};

const DELETE_PROPERTY_MEDIA = {
  LOAD: 'LOAD_DELETE_PROPERTY_MEDIA',
  SUCCESS: 'SUCCESS_DELETE_PROPERTY_MEDIA',
  FAIL: 'FAIL_DELETE_PROPERTY_MEDIA',
};

const UPLOAD_FILE = {
  LOAD: 'LOAD_UPLOAD_FILE',
  SUCCESS: 'SUCCESS_UPLOAD_FILE',
  FAIL: 'FAIL_UPLOAD_FILE',
};

const DELETE_FILE = {
  LOAD: 'LOAD_DELETE_FILE',
  SUCCESS: 'SUCCESS_DELETE_FILE',
  FAIL: 'FAIL_DELETE_FILE',
};

const ADD_ANNOUNCEMENT = {
  LOAD: 'LOAD_ADD_ANNOUNCEMENT',
  SUCCESS: 'SUCCESS_ADD_ANNOUNCEMENT',
  FAIL: 'FAIL_ADD_ANNOUNCEMENT',
};

const DELETE_ANNOUNCEMENT = {
  LOAD: 'LOAD_DELETE_ANNOUNCEMENT',
  SUCCESS: 'SUCCESS_DELETE_ANNOUNCEMENT',
  FAIL: 'FAIL_DELETE_ANNOUNCEMENT',
};

const ADD_MY_NOTE = {
  LOAD: 'LOAD_ADD_MY_NOTE',
  SUCCESS: 'SUCCESS_ADD_MY_NOTE',
  FAIL: 'FAIL_ADD_MY_NOTE',
};

const UPDATE_PROPERTY = {
  LOAD: 'LOAD_UPDATE_PROPERTY',
  SUCCESS: 'SUCCESS_UPDATE_PROPERTY',
  FAIL: 'FAIL_UPDATE_PROPERTY',
};

const ANNOUNCEMENT_LIST = {
  LOAD: 'LOAD_ANNOUNCEMENT_LIST',
  SUCCESS: 'SUCCESS_ANNOUNCEMENT_LIST',
  FAIL: 'FAIL_ANNOUNCEMENT_LIST',
};

const GET_CLIENTS_LIST = {
  LOAD: 'LOAD_CLIENTS_LIST',
  SUCCESS: 'SUCCESS_CLIENTS_LIST',
  FAIL: 'FAIL_CLIENTS_LIST',
};

const ADD_NOTES = {
  LOAD: 'LOAD_ADD_NOTES',
  SUCCESS: 'SUCCESS_ADD_NOTES',
  FAIL: 'FAIL_ADD_NOTES',
};

const ADD_ISSUE_NOTE = {
  LOAD: 'LOAD_ADD_ISSUE_NOTES',
  SUCCESS: 'SUCCESS_ADD_ISSUE_NOTES',
  FAIL: 'FAIL_ADD_ISSUE_NOTES',
};

const NOTES_LIST = {
  LOAD: 'LOAD_NOTES_LIST_DATA',
  SUCCESS: 'SUCCESS_NOTES_LIST_DATA',
  FAIL: 'FAIL_NOTES_LIST_DATA',
};

const JOB_FORMS_LIST = {
  LOAD: 'LOAD_JOB_FORMS_LIST_DATA',
  SUCCESS: 'SUCCESS_JOB_FORMS_LIST_DATA',
  FAIL: 'FAIL_JOB_FORMS_LIST_DATA',
};

const CATEGORY_LIST = {
  LOAD: 'LOAD_CATEGORY_LIST_DATA',
  SUCCESS: 'SUCCESS_CATEGORY_LIST_DATA',
  FAIL: 'FAIL_CATEGORY_LIST_DATA',
};

const ADD_CATEGORY = {
  LOAD: 'LOAD_ADD_CATEGORY',
  SUCCESS: 'SUCCESS_ADD_CATEGORY',
  FAIL: 'FAIL_ADD_CATEGORY',
};

const ADD_JOB_FORM = {
  LOAD: 'LOAD_ADD_JOB_FORM',
  SUCCESS: 'SUCCESS_ADD_JOB_FORM',
  FAIL: 'FAIL_ADD_JOB_FORM',
};

const JOB_LIST = {
  LOAD: 'LOAD_JOB_LIST_DATA',
  SUCCESS: 'SUCCESS_JOB_LIST_DATA',
  FAIL: 'FAIL_JOB_LIST_DATA',
};

const ADD_JOB = {
  LOAD: 'LOAD_ADD_JOB',
  SUCCESS: 'SUCCESS_ADD_JOB',
  FAIL: 'FAIL_ADD_JOB',
};

const DROPDOWN_JOB_LIST = {
  LOAD: 'LOAD_DROPDOWN_JOB_LIST',
  SUCCESS: 'SUCCESS_DROPDOWN_JOB_LIST',
  FAIL: 'FAIL_DROPDOWN_JOB_LIST',
};

const ADD_ISSUE = {
  LOAD: 'LOAD_ADD_ISSUE',
  SUCCESS: 'SUCCESS_ADD_ISSUE',
  FAIL: 'FAIL_ADD_ISSUE',
};

const SEND_UPDATE = {
  LOAD: 'LOAD_SEND_UPDATE',
  SUCCESS: 'SUCCESS_SEND_UPDATE',
  FAIL: 'FAIL_SEND_UPDATE',
};

const ISSUES_LIST = {
  LOAD: 'LOAD_ISSUES_LIST',
  SUCCESS: 'SUCCESS_ISSUES_LIST',
  FAIL: 'FAIL_ISSUES_LIST',
};

const GET_CLIENT_BY_ID = {
  LOAD: 'LOAD_GET_CLIENT_BY_ID',
  SUCCESS: 'SUCCESS_GET_CLIENT_BY_ID',
  FAIL: 'FAIL_GET_CLIENT_BY_ID',
};

const GET_JOB_FORM_BY_ID = {
  LOAD: 'LOAD_GET_JOB_FORM_BY_ID',
  SUCCESS: 'SUCCESS_GET_JOB_FORM_BY_ID',
  FAIL: 'FAIL_GET_JOB_FORM_BY_ID',
};

const DELETE_JOB = {
  LOAD: 'LOAD_DELETE_JOB',
  SUCCESS: 'SUCCESS_DELETE_JOB',
  FAIL: 'FAIL_DELETE_JOB',
};

const GET_JOB_BY_ID = {
  LOAD: 'LOAD_GET_JOB_BY_ID',
  SUCCESS: 'SUCCESS_GET_JOB_BY_ID',
  FAIL: 'FAIL_GET_JOB_BY_ID',
  CLEAR: 'CLEAR_GET_JOB_BY_ID',
};

const UPDATE_JOB = {
  LOAD: 'LOAD_UPDATE_JOB',
  SUCCESS: 'SUCCESS_UPDATE_JOB',
  FAIL: 'FAIL_UPDATE_JOB',
};

const GET_ISSUE_BY_ID = {
  LOAD: 'LOAD_GET_ISSUE_BY_ID',
  SUCCESS: 'SUCCESS_GET_ISSUE_BY_ID',
  FAIL: 'FAIL_GET_ISSUE_BY_ID',
};

const DELETE_ISSUE = {
  LOAD: 'LOAD_DELETE_ISSUE',
  SUCCESS: 'SUCCESS_DELETE_ISSUE',
  FAIL: 'FAIL_DELETE_ISSUE',
};

const CANCEL_RECURRENCE = {
  LOAD: 'LOAD_CANCEL_RECURRENCE',
  SUCCESS: 'SUCCESS_CANCEL_RECURRENCE',
  FAIL: 'FAIL_CANCEL_RECURRENCE',
};

const UPDATE_RECURRENCE = {
  LOAD: 'LOAD_UPDATE_RECURRENCE',
  SUCCESS: 'SUCCESS_UPDATE_RECURRENCE',
  FAIL: 'FAIL_UPDATE_RECURRENCE',
};

const UPDATE_ISSUE = {
  LOAD: 'LOAD_UPDATE_ISSUE',
  SUCCESS: 'SUCCESS_UPDATE_ISSUE',
  FAIL: 'FAIL_UPDATE_ISSUE',
};

const UPDATE_ISSUE_BY_PERFORM_ACTION = {
  LOAD: 'LOAD_UPDATE_ISSUE_BY_PERFORM_ACTION',
  SUCCESS: 'SUCCESS_UPDATE_ISSUE_BY_PERFORM_ACTION',
  FAIL: 'FAIL_UPDATE_ISSUE_BY_PERFORM_ACTION',
};

const GET_ISSUES_BY_PROPERTY = {
  LOAD: 'LOAD_GET_ISSUES_BY_PROPERTY',
  SUCCESS: 'SUCCESS_GET_ISSUES_BY_PROPERTY',
  FAIL: 'FAIL_GET_ISSUES_BY_PROPERTY',
};

const GET_JOBS_BY_PROPERTY = {
  LOAD: 'LOAD_GET_JOBS_BY_PROPERTY',
  SUCCESS: 'SUCCESS_GET_JOBS_BY_PROPERTY',
  FAIL: 'FAIL_GET_JOBS_BY_PROPERTY',
};

const GET_MEDIA_BY_PROPERTY = {
  LOAD: 'LOAD_GET_MEDIA_BY_PROPERTY',
  SUCCESS: 'SUCCESS_GET_MEDIA_BY_PROPERTY',
  FAIL: 'FAIL_GET_MEDIA_BY_PROPERTY',
};

const GET_CLIENT_ALL_PROPERTY = {
  LOAD: 'LOAD_GET_CLIENT_ALL_PROPERTY',
  SUCCESS: 'SUCCESS_GET_CLIENT_ALL_PROPERTY',
  FAIL: 'FAIL_GET_CLIENT_ALL_PROPERTY',
};

const ADD_JUNCTURE = {
  LOAD: 'LOAD_ADD_JUNCTURE',
  SUCCESS: 'SUCCESS_ADD_JUNCTURE',
  FAIL: 'FAIL_ADD_JUNCTURE',
};

const JUNCTURES_LIST = {
  LOAD: 'LOAD_JUNCTURES_LIST_DATA',
  SUCCESS: 'SUCCESS_JUNCTURES_LIST_DATA',
  FAIL: 'FAIL_JUNCTURES_LIST_DATA',
};

const SEND_REPORT = {
  LOAD: 'LOAD_SEND_REPORT',
  SUCCESS: 'SUCCESS_SEND_REPORT',
  FAIL: 'FAIL_SEND_REPORT',
};

const CONFIRM_ISSUE = {
  LOAD: 'LOAD_CONFIRM_ISSUE',
  SUCCESS: 'SUCCESS_CONFIRM_ISSUE',
  FAIL: 'FAIL_CONFIRM_ISSUE',
};

const SUPPLIES_NEEDED = {
  LOAD: 'LOAD_SUPPLIES_NEEDED_DATA',
  SUCCESS: 'SUCCESS_SUPPLIES_NEEDED_DATA',
  FAIL: 'FAIL_SUPPLIES_NEEDED_DATA',
};

const TEAM_DISCUSSION = {
  LOAD: 'LOAD_TEAM_DISCUSSION',
  SUCCESS: 'SUCCESS_TEAM_DISCUSSION',
  FAIL: 'FAIL_TEAM_DISCUSSION',
};

const GET_REPORT = {
  LOAD: 'LOAD_GET_REPORT',
  SUCCESS: 'SUCCESS_GET_REPORT',
  FAIL: 'FAIL_GET-REPORT',
};

const DOWNLOAD_REPORT = {
  LOAD: 'LOAD_DOWNLOAD_REPORT',
  SUCCESS: 'SUCCESS_DOWNLOAD_REPORT',
  FAIL: 'FAIL_DOWNLOAD_REPORT',
};

const DOWNLOAD_SUPPLIES = {
  LOAD: 'LOAD_DOWNLOAD_SUPPLIES',
  SUCCESS: 'SUCCESS_DOWNLOAD_SUPPLIES',
  FAIL: 'FAIL_DOWNLOAD_SUPPLIES',
};

const UPDATE_REPORT = {
  LOAD: 'LOAD_UPDATE_REPORT',
  SUCCESS: 'SUCCESS_UPDATE_REPORT',
  FAIL: 'FAIL_UPDATE_REPORT',
};

const UPDATE_REPORT_ACTION = {
  LOAD: 'LOAD_UPDATE_REPORT_ACTION',
  SUCCESS: 'SUCCESS_UPDATE_REPORT_ACTION',
  FAIL: 'FAIL_UPDATE_REPORT_ACTION',
};

const DASHBOARD_ISSUE_LIST = {
  LOAD: 'LOAD_DASHBOARD_ISSUE_LIST',
  SUCCESS: 'SUCCESS_DASHBOARD_ISSUE_LIST',
  FAIL: 'FAIL_DASHBOARD_ISSUE_LIST',
};

const UPDATE_JUNCTURE = {
  LOAD: 'LOAD_UPDATE_JUNCTURE',
  SUCCESS: 'SUCCESS_UPDATE_JUNCTURE',
  FAIL: 'FAIL_UPDATE_JUNCTURE',
};

const GET_CLIENTS_AVAILABILITY = {
  LOAD: 'LOAD_GET_CLIENTS_AVAILABILITY',
  SUCCESS: 'SUCCESS_GET_CLIENTS_AVAILABILITY',
  FAIL: 'FAIL_GET_CLIENTS_AVAILABILITY',
};

const GET_LATEST_REPORT = {
  LOAD: 'LOAD_GET_LATEST_REPORT',
  SUCCESS: 'SUCCESS_GET_LATEST_REPORT',
  FAIL: 'FAIL_GET_LATEST_REPORT',
};

const CLIENT_GET_ADMIN = {
  LOAD: 'LOAD_CLIENT_GET_ADMIN',
  SUCCESS: 'SUCCESS_CLIENT_GET_ADMIN',
  FAIL: 'FAIL_CLIENT_GET_ADMIN',
};

const DASHBOARD_COUNT = {
  LOAD: 'LOAD_DASHBOARD_COUNT',
  SUCCESS: 'SUCCESS_DASHBOARD_COUNT',
  FAIL: 'FAIL_DASHBOARD_COUNT',
};

const NOTIFICATION_COUNT = {
  LOAD: 'LOAD_NOTIFICATION_COUNT',
  SUCCESS: 'SUCCESS_NOTIFICATION_COUNT',
  FAIL: 'FAIL_NOTIFICATION_COUNT',
};

const NOTIFICATION_LIST = {
  LOAD: 'LOAD_NOTIFICATION_LIST_DATA',
  SUCCESS: 'SUCCESS_NOTIFICATION_LIST_DATA',
  FAIL: 'FAIL_NOTIFICATION_LIST_DATA',
};

const CLEAR_NOTIFICATIONS = {
  LOAD: 'LOAD_CLEAR_NOTIFICATIONS',
  SUCCESS: 'SUCCESS_CLEAR_NOTIFICATIONS',
  FAIL: 'FAIL_CLEAR_NOTIFICATIONS',
};

const SUPER_ADMIN_DASHBOARD_ISSUES_LIST = {
  LOAD: 'LOAD_SUPER_ADMIN_DASHBOARD_ISSUES_LIST',
  SUCCESS: 'SUCCESS_SUPER_ADMIN_DASHBOARD_ISSUES_LIST',
  FAIL: 'FAIL_SUPER_ADMIN_DASHBOARD_ISSUES_LIST',
};

const ADMIN_JUNCTURE_LIST = {
  LOAD: 'LOAD_ADMIN_JUNCTURE_LIST',
  SUCCESS: 'SUCCESS_ADMIN_JUNCTURE_LIST',
  FAIL: 'FAIL_ADMIN_JUNCTURE_LIST',
};

const MEDIA_LIST = {
  LOAD: 'LOAD_MEDIA_LIST_DATA',
  SUCCESS: 'SUCCESS_MEDIA_LIST_DATA',
  FAIL: 'FAIL_MEDIA_LIST_DATA',
};

const GET_ROOMS_LIST = {
  LOAD: 'LOAD_ROOMS_LIST',
  SUCCESS: 'SUCCESS_ROOMS_LIST',
  FAIL: 'FAIL_ROOMS_LIST',
};

const CREATE_CHAT_ROOM = {
  LOAD: 'LOAD_CREATE_CHAT_ROOM',
  SUCCESS: 'SUCCESS_CREATE_CHAT_ROOM',
  FAIL: 'FAIL_CREATE_CHAT_ROOM',
};

const CREATE_MESSAGE = {
  LOAD: 'LOAD_CREATE_MESSAGE',
  SUCCESS: 'SUCCESS_CREATE_MESSAGE',
  FAIL: 'FAIL_CREATE_MESSAGE',
};

const GET_ROOM_DATA = {
  LOAD: 'LOAD_GET_ROOM_DATA',
  SUCCESS: 'SUCCESS_GET_ROOM_DATA',
  FAIL: 'FAIL_GET_ROOM_DATA',
};

const CLIENT_FEEDBACK_LIST = {
  LOAD: 'LOAD_CLIENT_FEEDBACK_LIST',
  SUCCESS: 'SUCCESS_CLIENT_FEEDBACK_LIST',
  FAIL: 'FAIL_CLIENT_FEEDBACK_LIST',
};

const CALENDAR_DATA = {
  LOAD: 'LOAD_CALENDAR_DATA',
  SUCCESS: 'SUCCESS_CALENDAR_DATA',
  FAIL: 'FAIL_CALENDAR_DATA',
};

const EXPORT_JUNCTURES = {
  LOAD: 'LOAD_EXPORT_JUNCTURES',
  SUCCESS: 'SUCCESS_EXPORT_JUNCTURES',
  FAIL: 'FAIL_EXPORT_JUNCTURES',
};

const SEND_USER_PASSWORD = {
  LOAD: 'LOAD_SEND_USER_PASSWORD',
  SUCCESS: 'SUCCESS_SEND_USER_PASSWORD',
  FAIL: 'FAIL_SEND_USER_PASSWORD',
};

const FORGOT_PASSWORD = {
  LOAD: 'LOAD_FORGOT_PASSWORD',
  SUCCESS: 'SUCCESS_FORGOT_PASSWORD',
  FAIL: 'FAIL_FORGOT_PASSWORD',
};

const SEND_FEEDBACK = {
  LOAD: 'LOAD_SEND_FEEDBACK',
  SUCCESS: 'SUCCESS_SEND_FEEDBACK',
  FAIL: 'FAIL_SEND_FEEDBACK',
};

const ADD_JOB_NOTE = {
  LOAD: 'LOAD_ADD_JOB_NOTE',
  SUCCESS: 'SUCCESS_ADD_JOB_NOTE',
  FAIL: 'FAIL_ADD_JOB_NOTE',
};

const CALENDAR_JUNCTURES = {
  LOAD: 'LOAD_CALENDAR_JUNCTURES_DATA',
  SUCCESS: 'SUCCESS_CALENDAR_JUNCTURES_DATA',
  FAIL: 'FAIL_CALENDAR_JUNCTURES_DATA',
};

const DELETE_USER = {
  LOAD: 'LOAD_DELETE_USER',
  SUCCESS: 'SUCCESS_DELETE_USER',
  FAIL: 'FAIL_DELETE_USER',
};

const DELETE_CATEGORY = {
  LOAD: 'LOAD_DELETE_CATEGORY',
  SUCCESS: 'SUCCESS_DELETE_CATEGORY',
  FAIL: 'FAIL_DELETE_CATEGORY',
};
const UPDATE_CATEGORY = {
  LOAD: 'LOAD_UPDATE_CATEGORY',
  SUCCESS: 'SUCCESS_UPDATE_CATEGORY',
  FAIL: 'FAIL_UPDATE_CATEGORY',
};

const UPDATE_JOB_FORM = {
  LOAD: 'LOAD_UPDATE_JOB_FORM',
  SUCCESS: 'SUCCESS_UPDATE_JOB_FORM',
  FAIL: 'FAIL_UPDATE_JOB_FORM',
};

const VENDOR_DROP_DOWN_LIST = {
  LOAD: 'LOAD_VENDOR_DROP_DOWN_LIST',
  SUCCESS: 'SUCCESS_VENDOR_DROP_DOWN_LIST',
  FAIL: 'FAIL_VENDOR_DROP_DOWN_LIST',
};

const UPDATE_PROGRAM_TYPE = {
  LOAD: 'LOAD_UPDATE_PROGRAM_TYPE',
  SUCCESS: 'SUCCESS_UPDATE_PROGRAM_TYPE',
  FAIL: 'FAIL_UPDATE_PROGRAM_TYPE',
};

const DELETE_PROGRAM_TYPE = {
  LOAD: 'LOAD_DELETE_PROGRAM_TYPE',
  SUCCESS: 'SUCCESS_DELETE_PROGRAM_TYPE',
  FAIL: 'FAIL_DELETE_PROGRAM_TYPE',
};

const VISIBLE_ISSUE = {
  LOAD: 'LOAD_VISIBLE_ISSUE',
  SUCCESS: 'SUCCESS_VISIBLE_ISSUE',
  FAIL: 'FAIL_VISIBLE_ISSUE',
};

const TAGS_LIST = {
  LOAD: 'LOAD_TAGS_LIST',
  SUCCESS: 'SUCCESS_TAGS_LIST',
  FAIL: 'FAIL_TAGS_LIST',
};

const CREATE_TAG = {
  LOAD: 'LOAD_CREATE_TAG',
  SUCCESS: 'SUCCESS_CREATE_TAG',
  FAIL: 'FAIL_CREATE_TAG',
};

const DELETE_TAG = {
  LOAD: 'LOAD_DELETE_TAG',
  SUCCESS: 'SUCCESS_DELETE_TAG',
  FAIL: 'FAIL_DELETE_TAG',
};

const JUNCTURES_LIST_BY_PROPERTY = {
  LOAD: 'LOAD_JUNCTURES_LIST_BY_PROPERTY',
  SUCCESS: 'SUCCESS_JUNCTURES_LIST_BY_PROPERTY',
  FAIL: 'FAIL_JUNCTURES_LIST_BY_PROPERTY',
};

const UPDATE_JOB_NOTE = {
  LOAD: 'LOAD_UPDATE_JOB_NOTE',
  SUCCESS: 'SUCCESS_UPDATE_JOB_NOTE',
  FAIL: 'FAIL_UPDATE_JOB_NOTE',
};

const DELETE_JOB_NOTE = {
  LOAD: 'LOAD_DELETE_JOB_NOTE',
  SUCCESS: 'SUCCESS_DELETE_JOB_NOTE',
  FAIL: 'FAIL_DELETE_JOB_NOTE',
};

const UPDATE_ISSUE_NOTE = {
  LOAD: 'LOAD_UPDATE_ISSUE_NOTE',
  SUCCESS: 'SUCCESS_UPDATE_ISSUE_NOTE',
  FAIL: 'FAIL_UPDATE_ISSUE_NOTE',
};

const DELETE_ISSUE_NOTE = {
  LOAD: 'LOAD_DELETE_ISSUE_NOTE',
  SUCCESS: 'SUCCESS_DELETE_ISSUE_NOTE',
  FAIL: 'FAIL_DELETE_ISSUE_NOTE',
};

const CLIENT_DIRECTIVE_REMINDER = {
  LOAD: 'LOAD_CLIENT_DIRECTIVE_REMINDER',
  SUCCESS: 'SUCCESS_CLIENT_DIRECTIVE_REMINDER',
  FAIL: 'FAIL_CLIENT_DIRECTIVE_REMINDER',
};

const UPLOAD_FILE_FOR_REPORT = {
  LOAD: 'LOAD_UPLOAD_FILE_FOR_REPORT',
  SUCCESS: 'SUCCESS_UPLOAD_FILE_FOR_REPORT',
  FAIL: 'FAIL_UPLOAD_FILE_FOR_REPORT',
};

const MARK_AS_COMPLETE_JOB = {
  LOAD: 'LOAD_MARK_AS_COMPLETE_JOB',
  SUCCESS: 'SUCCESS_MARK_AS_COMPLETE_JOB',
  FAIL: 'FAIL_MARK_AS_COMPLETE_JOB',
};

const CLIENT_LIST_NAME = {
  LOAD: 'LOAD_CLIENT_LIST_NAME',
  SUCCESS: 'SUCCESS_CLIENT_LIST_NAME',
  FAIL: 'FAIL_CLIENT_LIST_NAME',
};

const CLIENT_JOBS = {
  LOAD: 'LOAD_CLIENT_JOBS',
  SUCCESS: 'SUCCESS_CLIENT_JOBS',
  FAIL: 'FAIL_CLIENT_JOBS',
};

const UPLOAD_PROPERTY_MEDIA = {
  LOAD: 'LOAD_UPLOAD_PROPERTY_MEDIA',
  SUCCESS: 'SUCCESS_UPLOAD_PROPERTY_MEDIA',
  FAIL: 'FAIL_UPLOAD_PROPERTY_MEDIA',
};

const GET_MEDIA_BY_ID = {
  LOAD: 'LOAD_GET_MEDIA_BY_ID',
  SUCCESS: 'SUCCESS_GET_MEDIA_BY_ID',
  FAIL: 'FAIL_GET_MEDIA_BY_ID',
};

const UPDATE_FILE_DETAILS = {
  LOAD: 'LOAD_UPDATE_FILE_DETAILS',
  SUCCESS: 'SUCCESS_UPDATE_FILE_DETAILS',
  FAIL: 'FAIL_UPDATE_FILE_DETAILS',
};

const MEDIA_LIST_FOLDER = {
  LOAD: 'LOAD_MEDIA_LIST_FOLDER',
  SUCCESS: 'SUCCESS_MEDIA_LIST_FOLDER',
  FAIL: 'FAIL_MEDIA_LIST_FOLDER',
};

const GET_MEDIA_FOLDER_CONTENT = {
  LOAD: 'LOAD_GET_MEDIA_FOLDER_CONTENT',
  SUCCESS: 'SUCCESS_GET_MEDIA_FOLDER_CONTENT',
  FAIL: 'FAIL_GET_MEDIA_FOLDER_CONTENT',
};

const UPDATE_FOLDER_NAME = {
  LOAD: 'LOAD_UPDATE_FOLDER_NAME',
  SUCCESS: 'SUCCESS_UPDATE_FOLDER_NAME',
  FAIL: 'FAIL_UPDATE_FOLDER_NAME',
};

const CREATE_FILTER = {
  LOAD: 'LOAD_CREATE_FILTER',
  SUCCESS: 'SUCCESS_CREATE_FILTER',
  FAIL: 'FAIL_CREATE_FILTER',
};

const LIST_FILTER = {
  LOAD: 'LOAD_LIST_FILTER',
  SUCCESS: 'SUCCESS_LIST_FILTER',
  FAIL: 'FAIL_LIST_FILTER',
};

const GET_FILTER_BY_ID = {
  LOAD: 'LOAD_GET_FILTER_BY_ID',
  SUCCESS: 'SUCCESS_GET_FILTER_BY_ID',
  FAIL: 'FAIL_GET_FILTER_BY_ID',
};

const UPDATE_FILTER = {
  LOAD: 'LOAD_UPDATE_FILTER',
  SUCCESS: 'SUCCESS_UPDATE_FILTER',
  FAIL: 'FAIL_UPDATE_FILTER',
};

const DELETE_FILTER = {
  LOAD: 'LOAD_DELETE_FILTER',
  SUCCESS: 'SUCCESS_DELETE_FILTER',
  FAIL: 'FAIL_DELETE_FILTER',
};

const CREATE_FOLDER = {
  LOAD: 'LOAD_CREATE_FOLDER',
  SUCCESS: 'SUCCESS_CREATE_FOLDER',
  FAIL: 'FAIL_CREATE_FOLDER',
};

const UPLOAD_FOLDER_MEDIA = {
  LOAD: 'LOAD_UPLOAD_FOLDER_MEDIA',
  SUCCESS: 'SUCCESS_UPLOAD_FOLDER_MEDIA',
  FAIL: 'FAIL_UPLOAD_FOLDER_MEDIA',
};

const DELETE_JUNCTURE = {
  LOAD: 'LOAD_DELETE_JUNCTURE',
  SUCCESS: 'SUCCESS_DELETE_JUNCTURE',
  FAIL: 'FAIL_DELETE_JUNCTURE',
};

const MARK_ALL_JOB_NOTES_READ = {
  LOAD: 'LOAD_MARK_ALL_JOB_NOTES_READ',
  SUCCESS: 'SUCCESS_MARK_ALL_JOB_NOTES_READ',
  FAIL: 'FAIL_MARK_ALL_JOB_NOTES_READ',
};

const MARK_ALL_ISSUE_NOTES_READ = {
  LOAD: 'LOAD_MARK_ALL_ISSUE_NOTES_READ',
  SUCCESS: 'SUCCESS_MARK_ALL_ISSUE_NOTES_READ',
  FAIL: 'FAIL_MARK_ALL_ISSUE_NOTES_READ',
};

const UPDATE_APPOINTMENT = {
  LOAD: 'LOAD_UPDATE_APPOINTMENT',
  SUCCESS: 'SUCCESS_UPDATE_APPOINTMENT',
  FAIL: 'FAIL_UPDATE_APPOINTMENT',
};

const EXPORT_ISSUES = {
  LOAD: 'LOAD_EXPORT_ISSUES',
  SUCCESS: 'SUCCESS_EXPORT_ISSUES',
  FAIL: 'FAIL_EXPORT_ISSUES',
};

const DROPDOWN_ISSUE_LIST = {
  LOAD: 'LOAD_DROPDOWN_ISSUE_LIST',
  SUCCESS: 'SUCCESS_DROPDOWN_ISSUE_LIST',
  FAIL: 'FAIL_DROPDOWN_ISSUE_LIST',
};

const DELETE_NOTIFICATION = {
  LOAD: 'LOAD_DELETE_NOTIFICATION',
  SUCCESS: 'SUCCESS_DELETE_NOTIFICATION',
  FAIL: 'FAIL_DELETE_NOTIFICATION',
};

const GET_EBLAST = {
  LOAD: 'LOAD_GET_EBLAST',
  SUCCESS: 'SUCCESS_GET_EBLAST',
  FAIL: 'FAIL_GET_EBLAST',
};

const UPDATE_EBLAST = {
  LOAD: 'LOAD_UPDATE_EBLAST',
  SUCCESS: 'SUCCESS_UPDATE_EBLAST',
  FAIL: 'FAIL_UPDATE_EBLAST',
};

const COPY_MEDIA = {
  LOAD: 'LOAD_COPY_MEDIA',
  SUCCESS: 'SUCCESS_COPY_MEDIA',
  FAIL: 'FAIL_COPY_MEDIA',
};

const GET_FORECAST_WEATHER = {
  LOAD: 'LOAD_GET_FORECAST_WEATHER',
  SUCCESS: 'SUCCESS_GET_FORECAST_WEATHER',
  FAIL: 'FAIL_GET_FORECAST_WEATHER',
};

const GET_CURRENT_WEATHER = {
  LOAD: 'LOAD_GET_CURRENT_WEATHER',
  SUCCESS: 'SUCCESS_GET_CURRENT_WEATHER',
  FAIL: 'FAIL_GET_CURRENT_WEATHER',
};

const SEARCH_MEDIA = {
  LOAD: 'LOAD_SEARCH_MEDIA',
  SUCCESS: 'SUCCESS_SEARCH_MEDIA',
  FAIL: 'FAIL_SEARCH_MEDIA',
  CLEAR: 'CLEAR_SEARCH_MEDIA',
};

const DROPDOWN_PROPERTY_LIST = {
  LOAD: 'LOAD_DROPDOWN_PROPERTY_LIST',
  SUCCESS: 'SUCCESS_DROPDOWN_PROPERTY_LIST',
  FAIL: 'FAIL_DROPDOWN_PROPERTY_LIST',
};

const JOB_SERIES_LIST = {
  LOAD: 'LOAD_JOB_SERIES_LIST',
  SUCCESS: 'SUCCESS_JOB_SERIES_LIST',
  FAIL: 'FAIL_JOB_SERIES_LIST',
};

const DELETE_JOB_SERIES = {
  LOAD: 'LOAD_DELETE_JOB_SERIES',
  SUCCESS: 'SUCCESS_DELETE_JOB_SERIES',
  FAIL: 'FAIL_DELETE_JOB_SERIES',
};

const DELETE_JOB_FORM = {
  LOAD: 'LOAD_DELETE_JOB_FORM',
  SUCCESS: 'SUCCESS_DELETE_JOB_FORM',
  FAIL: 'FAIL_DELETE_JOB_FORM',
};

const CLEAR_ALL_CLIENT_FEEDBACK = {
  LOAD: 'LOAD_CLEAR_ALL_CLIENT_FEEDBACK',
  SUCCESS: 'SUCCESS_CLEAR_ALL_CLIENT_FEEDBACK',
  FAIL: 'FAIL_CLEAR_ALL_CLIENT_FEEDBACK',
};

const CLEAR_CLIENT_FEEDBACK = {
  LOAD: 'LOAD_CLEAR_CLIENT_FEEDBACK',
  SUCCESS: 'SUCCESS_CLEAR_CLIENT_FEEDBACK',
  FAIL: 'FAIL_CLEAR_CLIENT_FEEDBACK',
};

const DELETE_MEDIA_FOLDER = {
  LOAD: 'LOAD_DELETE_MEDIA_FOLDER',
  SUCCESS: 'SUCCESS_DELETE_MEDIA_FOLDER',
  FAIL: 'FAIL_DELETE_MEDIA_FOLDER',
};

const DELETE_MEDIA = {
  LOAD: 'LOAD_DELETE_MEDIA',
  SUCCESS: 'SUCCESS_DELETE_MEDIA',
  FAIL: 'FAIL_DELETE_MEDIA',
};

const UPDATE_JOB_SERIES = {
  LOAD: 'LOAD_UPDATE_JOB_SERIES',
  SUCCESS: 'SUCCESS_UPDATE_JOB_SERIES',
  FAIL: 'FAIL_UPDATE_JOB_SERIES',
};

export {
  LOGIN,
  ADD_USER,
  TEAM_USER_LIST,
  ADD_VENDOR,
  UPDATE_VENDOR,
  DELETE_VENDOR,
  USER_LIST_BY_TYPE,
  VENDOR_LIST,
  ADD_PROGRAM,
  PROGRAM_LIST,
  ADD_CLIENT,
  ARCHIVE_UNARCHIVE_CLIENT,
  CLIENT_LIST,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  ADD_PROPERTY,
  GET_PROPERTY_BY_ID,
  PROPERTIES_LIST,
  DELETE_PROPERTY,
  UPLOAD_FILE,
  DELETE_FILE,
  ADD_ANNOUNCEMENT,
  UPDATE_PROPERTY,
  ANNOUNCEMENT_LIST,
  GET_CLIENTS_LIST,
  ADD_NOTES,
  NOTES_LIST,
  JOB_FORMS_LIST,
  CATEGORY_LIST,
  ADD_CATEGORY,
  ADD_JOB_FORM,
  JOB_LIST,
  ADD_JOB,
  DROPDOWN_JOB_LIST,
  ADD_ISSUE,
  ISSUES_LIST,
  GET_CLIENT_BY_ID,
  DELETE_JOB,
  GET_JOB_BY_ID,
  UPDATE_JOB,
  GET_ISSUE_BY_ID,
  DELETE_ISSUE,
  CANCEL_RECURRENCE,
  UPDATE_RECURRENCE,
  UPDATE_ISSUE,
  GET_ISSUES_BY_PROPERTY,
  GET_JOBS_BY_PROPERTY,
  GET_CLIENT_ALL_PROPERTY,
  UPDATE_ISSUE_BY_PERFORM_ACTION,
  ADD_ISSUE_NOTE,
  ADD_JUNCTURE,
  JUNCTURES_LIST,
  SEND_REPORT,
  SUPPLIES_NEEDED,
  GET_REPORT,
  DASHBOARD_ISSUE_LIST,
  UPDATE_JUNCTURE,
  GET_CLIENTS_AVAILABILITY,
  GET_LATEST_REPORT,
  CLIENT_GET_ADMIN,
  DASHBOARD_COUNT,
  SUPER_ADMIN_DASHBOARD_ISSUES_LIST,
  ADMIN_JUNCTURE_LIST,
  MEDIA_LIST,
  GET_MEDIA_BY_PROPERTY,
  GET_ROOMS_LIST,
  CREATE_CHAT_ROOM,
  CREATE_MESSAGE,
  GET_ROOM_DATA,
  CLIENT_FEEDBACK_LIST,
  CALENDAR_DATA,
  EXPORT_JUNCTURES,
  SEND_USER_PASSWORD,
  FORGOT_PASSWORD,
  SEND_FEEDBACK,
  ADD_JOB_NOTE,
  CALENDAR_JUNCTURES,
  DELETE_USER,
  UPDATE_USER,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  GET_JOB_FORM_BY_ID,
  UPDATE_JOB_FORM,
  NOTIFICATION_COUNT,
  NOTIFICATION_LIST,
  CONFIRM_ISSUE,
  VENDOR_DROP_DOWN_LIST,
  UPDATE_PROGRAM_TYPE,
  DELETE_PROGRAM_TYPE,
  VISIBLE_ISSUE,
  TAGS_LIST,
  CREATE_TAG,
  DELETE_TAG,
  SEND_UPDATE,
  JUNCTURES_LIST_BY_PROPERTY,
  UPDATE_JOB_NOTE,
  DELETE_JOB_NOTE,
  UPDATE_ISSUE_NOTE,
  DELETE_ISSUE_NOTE,
  CLIENT_DIRECTIVE_REMINDER,
  CLEAR_NOTIFICATIONS,
  UPDATE_REPORT,
  UPLOAD_FILE_FOR_REPORT,
  DOWNLOAD_REPORT,
  MARK_AS_COMPLETE_JOB,
  CLIENT_LIST_NAME,
  CLIENT_JOBS,
  UPLOAD_PROPERTY_MEDIA,
  GET_MEDIA_BY_ID,
  UPDATE_FILE_DETAILS,
  MEDIA_LIST_FOLDER,
  GET_MEDIA_FOLDER_CONTENT,
  UPDATE_FOLDER_NAME,
  CREATE_FILTER,
  LIST_FILTER,
  GET_FILTER_BY_ID,
  UPDATE_FILTER,
  DELETE_FILTER,
  CREATE_FOLDER,
  UPLOAD_FOLDER_MEDIA,
  DELETE_JUNCTURE,
  MARK_ALL_JOB_NOTES_READ,
  MARK_ALL_ISSUE_NOTES_READ,
  UPDATE_APPOINTMENT,
  EXPORT_ISSUES,
  DROPDOWN_ISSUE_LIST,
  DELETE_NOTIFICATION,
  GET_EBLAST,
  UPDATE_EBLAST,
  COPY_MEDIA,
  GET_FORECAST_WEATHER,
  GET_CURRENT_WEATHER,
  SEARCH_MEDIA,
  DROPDOWN_PROPERTY_LIST,
  ADD_MY_NOTE,
  TEAM_DISCUSSION,
  DOWNLOAD_SUPPLIES,
  JOB_SERIES_LIST,
  DELETE_JOB_SERIES,
  DELETE_JOB_FORM,
  UPDATE_REPORT_ACTION,
  CLEAR_ALL_CLIENT_FEEDBACK,
  CLEAR_CLIENT_FEEDBACK,
  DELETE_ANNOUNCEMENT,
  DELETE_PROPERTY_MEDIA,
  DELETE_MEDIA_FOLDER,
  DELETE_MEDIA,
  UPDATE_JOB_SERIES,
};
